<template>
    <div class="Allocate">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <ef-biz-code v-model="form.code" />
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调入机构">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调出机构">
                            <el-select placeholder="请选择" v-model="form.outDeptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <EfAllocateType v-model="form.allocateType" />
                    </el-col>
                    <el-col :span="8">
                        <ef-review-status v-model="form.reviewStatus" />
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.stock.allocate.open')">
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.stock.allocate.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleAudit"
                size="small"
                v-if="hasPrivilege('menu.stock.allocate.review')"
                >审核
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.stock.allocate.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                ref="table"
                id="printMe"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="430"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" width="240" prop="code" v-if="showColumn('code')" sortable />
                <el-table-column label="调入机构" width="160" prop="deptName" v-if="showColumn('deptName')" sortable />
                <el-table-column label="调出机构" width="160" prop="repoName" v-if="showColumn('repoName')" sortable />
                <el-table-column
                    label="类型"
                    width="140"
                    prop="allocateType"
                    key="allocateType"
                    v-if="showColumn('allocateType')"
                    sortable
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.allocateType | allocateType }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="审核状态"
                    width="140"
                    prop="reviewStatus"
                    key="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                    sortable
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="120" prop="creator" sortable v-if="showColumn('creator')" />
                <el-table-column
                    label="创建时间"
                    width="160"
                    prop="createTime"
                    sortable
                    v-if="showColumn('createTime')"
                />

                <el-table-column
                    label="操作"
                    min-width="275"
                    header-align="center"
                    key="operate"
                    prop="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.stock.allocate.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            :disabled="0 != scope.row.reviewStatus"
                            v-if="hasPrivilege('menu.stock.allocate.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            :disabled="0 != scope.row.reviewStatus"
                            v-if="hasPrivilege('menu.stock.allocate.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
        <ef-review-biz ref="review" baseUrl="/stock/allocate" @completed="handleQuery" />
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import EfReviewStatus from 'components/EfReviewStatus';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfBizCode from 'components/EfBizCode';
import EfAllocateType from 'components/EfAllocateType';
import EfReviewBiz from 'components/EfReviewBiz';
import EfPagination from 'components/EfPagination';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Allocate',
    components: {
        EfReviewBiz,
        EfPagination,
        CheckTableShowColumnDialog,
        EfAllocateType,
        EfReviewStatus,
        EfEndDate,
        EfBizCode,
        EfStartDate,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                code: '',
                deptCode: '',
                outDeptCode: '',
                allocateType: null,
                reviewStatus: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            total: 0,
            loading: false,
            tableData: [],
            url: {
                page: '/stock/allocate/page',
                delete: '/stock/allocate/delete',
                review: '/stock/allocate/review/',
            },
            depts: [],
        };
    },
    mounted() {
        this.$efApi.deptApi.managedStoresAndRepos().then((rst) => {
            this.depts = rst;
            this.form.deptCode = this.depts[0].code;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleAudit() {
            this.$refs.review.open(this.$refs.table.selection);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        queryList() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params, (rst) => {
                this.total = rst.count;
                this.tableData = rst.data;
            });
        },
        handleCreate() {
            Util.nameJump(this, 'menu.stock.allocate.create', ['库存管理', '调拨申请', '新建调拨']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.stock.allocate.detail', ['库存管理', '调拨申请', '调拨详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.stock.allocate.edit', ['库存管理', '调拨申请', '编辑调拨'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '调拨申请', '/stock/allocate/export', this.form, codes);
        },
        handleDelete(row) {
            if (row.reviewStatus != 0) {
                this.$message.error('非待审核单据不能删除');
            } else {
                UrlUtils.DeleteRemote(this, this.url.delete, row.code);
            }
        },
    },
    filters: {
        allocateType(d) {
            if (d == 1) {
                return '内部调拨';
            } else if (d == 2) {
                return '自建调拨';
            }
            return '';
        },
    },
};
</script>

<style scoped>
.Allocate .el-form-item {
    margin-bottom: 0;
}
</style>
