<template>
    <el-form-item label="类型">
        <el-select placeholder="请选择" v-model="allocateType">
            <el-option value="" label="请选择" />
            <el-option label="内部调拨" value="1" />
            <el-option label="自建调拨" value="2" />
        </el-select>
    </el-form-item>
</template>

<script>
export default {
    name: 'EfAllocateType',
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        selected: {
            type: [Number, String],
            default: '',
        },
    },
    data() {
        return {
            allocateType: '',
            arr: [],
        };
    },
    watch: {
        selected: {
            handler(n, o) {
                //假设：null与''等价
                if (n == null) {
                    n = '';
                }
                this.allocateType = n;
            },
            immediate: true,
        },
        allocateType: {
            handler(n, o) {
                this.$emit('change', n);
            },
            immediate: true,
        },
    },
};
</script>
